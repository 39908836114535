<template>
  <editor
    :init="tinyOptions"
    :api-key="apiKey"
    v-model="localValue"
    v-bind="this.$attrs"
  >
  </editor>
</template>

<style scoped>

</style>

<script>

import Editor from '@tinymce/tinymce-vue';

export default {
  name: "HtmlEditor",
  components: {Editor},

  props: {

    value: {
      type: String,
      required: false,
      default: ''
    },

    apiKey: {
      type: String,
      required: false,
      // There's got to be a better place for this...
      default: '7vji1erxmio5rx613x4mehl92wm1rwzhqrrzv3o1t41i6a51'
    },

  },

  data() {
    return {
      // We may want to support a generic "options" prop to abstract out TinyMCE later, thus the name
      tinyOptions: {
        width: '100%',
        height: '100%',
        plugins: 'autolink autoresize code emoticons fullscreen insertdatetime link lists noneditable paste preview quickbars searchreplace wordcount',
        menubar: true,

        link_assume_external_targets: true,
        link_context_toolbar: true,
      }
    }
  },

  computed: {

    // Having to do this just to drill down v-model strikes me as really wrong...
    localValue: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  }
}
</script>

