// Nothing in here deserves refactoring into its own dedicated file yet

import moment from "moment";

// Not usually necessary with Vue: this is how {{someobject}} interpolates by default
export function toJsonPretty(string) {
	return JSON.stringify(string, null, 2);
}

export function upperFirst(word) {
	return word[0].toUpperCase() + word.slice(1).toLowerCase()
}

export let ucfirst = upperFirst		// PHP devs should like this

// I don't call this "dateFormat" because it should be clear we're using moment's format strings
export function momentFormat(date, fmt, utcToLocal = false) {
    if (date === null) {
        return null;
    }
    if (typeof(date) === 'string' && date.startsWith('-')) {
        // Negative dates are a nonsensical format PHP throws at us.
        // Don't try to handle it, just return null for that too.
        return null;
    }

  return utcToLocal ? moment.utc(date).local().format(fmt) : moment(date).format(fmt)
}

export const dateToYMD = date => momentFormat(date, "YYYY-MM-DD")
export const dateToDMY = date => momentFormat(date, "DD MMM YYYY")	// 03 Aug 2015

export const utcToLocalYMD = date => momentFormat(date, "YYYY-MM-DD hh:mm:ss", true)
export const utcToLocalDMY = date => momentFormat(date, "DD MMM YYYY hh:mm:ss", true)

export const dateToISO8601 = date => moment(date).format() // this is moment's default format.  never convert it to local.
export const humanizedDate = date => moment.utc(date).local().fromNow() // return a humanized date (a year from now, etc.)

export function currentTimeZoneName() {
  try {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  } catch {
    // something went wrong, probably because Intl doesn't exist.
    // We're not polyfilling Intl, so we'll just shrug and return null
    return null;
  }
}

export function ellipsize (str, len, ellipsis = '…') {
  return str.length <= len ? str : (str.slice(0, len - 1) + ellipsis)
}

export const zip = (...rows) => [...rows[0]].map((_,c) => rows.map(row => row[c]))

// https://github.com/monterail/vuelidate/issues/242
// This is the wrapper function: validator is the actual validation function, and delay is in milliseconds.
// Note that the validator function accepts two parameters: the value and a debounce function to wrap
// the asynchronous validation.
export function debounceAsyncValidator (validator, delay) {
    let currentTimer = null
    let currentPromiseReject = null

    function debounce () {
        return new Promise((resolve, reject) => {
            currentTimer = setTimeout(() => {
                currentTimer = null
                currentPromiseReject = null
                resolve()
            }, delay)
            currentPromiseReject = reject
        })
    }

    return function (value) {
        if (currentTimer) {
            currentPromiseReject(new Error('replaced'))
            clearTimeout(currentTimer)
            currentTimer = null
        }

        return validator.call(this, value, debounce)
    }
}

export function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export function toggle_include(arr, item) {
  return (arr.includes(item)) ? arr.filter(x => x !== item) : [...arr, item];
}
