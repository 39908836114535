<template>
  <b-button
            v-b-tooltip.hover.topright.ds500
            :variant="actionMapping.variant"
            :size="size"
            :href="href"
            :target="target"
            :style="buttonStyle"
            :title="buttonTitle"
            v-on="$listeners"
  >
    <i :class="iconClass" :style="iconStyle"></i>
    <slot></slot>
  </b-button>
</template>

<script>

import {upperFirst} from "../../common/utils";

const ACTION_MAP = {
  edit: {variant: 'primary'},
  add: {icon: 'plus', variant: 'success'},
  save: {icon: 'cloud-upload-alt', variant: 'success'},
  search: {variant: 'success'},
  clear: {icon: 'eraser', variant: 'secondary'},
  reset: {icon: 'eraser', variant: 'secondary'}, // same as clear for now
  settings: {icon: 'drafting-compass', variant: 'info'},
  delete: {icon: 'trash', variant: 'danger'},
  impersonate: {icon: 'people-arrows', variant: 'danger', style: {'text-stroke': 'none'}},
  pick: {icon: 'check', variant: 'primary'},
}

const DEFAULT_ICON_STYLE = {color: 'white', 'text-stroke': '1px #999'}

export default {

  props: {
    action: {
      type: String,
      required: true,
    },

    href: {
      type: String,
      required: false,
    },

    sameWindow: {
      type: Boolean,
      required: false,
      default: false,
    },

    size: {
      type: String,
      required: false,
    },

  },

  computed: {

    actionMapping() {
      return ACTION_MAP[this.action] || {variant: 'secondary'}
    },

    buttonStyle() {
      if (this.size === 'sm')
        return {'font-size': '0.75em'}
      if (this.size === 'md')
        return {'font-size': '1em', padding: '0.25em'}
      if (this.size === 'lg')
        return {'font-size': '1.25em', padding: '0.5em'}

      return null
    },

    buttonTitle() {
      return this.actionMapping.title || upperFirst(this.action)
    },

    iconClass() {
      const icon = this.actionMapping.icon || this.action
      return ['fas', `fa-${icon}`]
    },

    iconStyle() {
      return {...DEFAULT_ICON_STYLE, ...(this.actionMapping.style || {})}
    },

    target() {
      return this.sameWindow || !this.href
        ? null
        : 'eb_' + this.href.replace(/\W+/g, '_')
    },
  }
}
</script>
